import {
  APIGetRequest,
  APIParams,
  NearbyOutletsPaginatedResponse,
  OutletSearchListItem,
  PaginatedResponse,
} from '@ancon/wildcat-types'

import Request from '../../client/Request'
import endpoints from '../endpoints'

type OutletsAPI = {
  get: {
    nearby: APIGetRequest<
      NearbyOutletsPaginatedResponse,
      {
        // TODO: Move to wildcat-types

        offset?: number
        limit?: number

        /** @example "foo-bar" */
        search?: string
        /** @example 59.2752626 */
        latitude?: number
        /** @example 15.2134105 */
        longitude?: number
        /** @example 2 // (Tuesday) */
        dayOfWeek?: number
        /** @example 31 */
        orderFormats?: number
        urlSlug?: string
        outletId?: string
        /** @example 5 */
        systemVisibility?: number
        /** @example "state:asc,distance:asc,name:asc" */
        order?: string
        /** @example 20000 */
        radius?: number

        filter?: object
      }
    >
    search: APIGetRequest<PaginatedResponse<OutletSearchListItem>, APIParams>
  }
}

const outlets: OutletsAPI = {
  get: {
    nearby: new Request(endpoints.core.outlets.nearby.list).get,
    search: new Request(endpoints.core.outlets.search.list).get,
  },
}

export default outlets
