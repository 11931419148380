import { OrderFormat, DayOfWeek } from '@ancon/wildcat-types'

export const SupportedLanguage = {
  English: 'en',
  Swedish: 'sv',
  Norwegian: 'nb',
}

export const LanguageOptions = [
  {
    label: 'English',
    value: SupportedLanguage.English,
  },
  {
    label: 'Svenska',
    value: SupportedLanguage.Swedish,
  },
  {
    label: 'Norsk',
    value: SupportedLanguage.Norwegian,
  },
]

export const DefaultLanguage = SupportedLanguage.Swedish

export enum PortalHostId {
  Modal = 'ModalsPortal',
  Toast = 'ToastsPortal',
}

export enum AppRoutes {
  Home = '/',
  Outlets = '/outlets',
  Outlet = '/outlet',
  Checkout = '/checkout',
  Auth = '/auth',
  OrderStatus = '/order-status',
  QROrderStatus = '/order-status/qr',
  Account = '/account',
  Orders = '/orders',
  GiftCard = '/giftcard',
  PreOrder = '/pre-order',
  GroupOrder = '/group-order',
  PreOrderLink = '/pre-order/[preOrderId]',
  PreOrderManage = '/pre-order/manage',
  PreOrderStatus = '/pre-order/status',
  PayAndGoGroup = '/pay-and-go/[outletId]/group/[groupId]',
  PayAndGoPayment = '/pay-and-go/[outletId]/check/[checkId]/payment',
  PayAndGoStatus = '/pay-and-go/[outletId]/check/[checkId]/status',
}

/*
  NonOrderRoutes are routes that doesn't related to order process.
  - GiftCard: Preview of gift card
*/
export const NonOrderRoutes = [
  AppRoutes.Auth,
  AppRoutes.GiftCard,
  AppRoutes.PreOrderLink,
  AppRoutes.GroupOrder,
]

export const OrderFormatUrlSlug = {
  none: OrderFormat.None,
  'eat-in': OrderFormat.EatIn,
  'take-away': OrderFormat.TakeAway,
  'table-order': OrderFormat.TableOrder,
  delivery: OrderFormat.Delivery,
  'drive-thru': OrderFormat.DriveThru,
  'box-pickup': OrderFormat.BoxPickup,
}

export const GoogleMapsAPIUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places,geocoding,geometry&callback=Function.prototype`

export const DaysOfWeekMap: Record<number, DayOfWeek> = {
  0: DayOfWeek.Everyday,
  1: DayOfWeek.Monday,
  2: DayOfWeek.Tuesday,
  3: DayOfWeek.Wednesday,
  4: DayOfWeek.Thursday,
  5: DayOfWeek.Friday,
  6: DayOfWeek.Saturday,
  7: DayOfWeek.Sunday,
}

export const OrderFormatSlugMap = {
  [OrderFormat.None]: 'none',
  [OrderFormat.EatIn]: 'eat-in',
  [OrderFormat.TakeAway]: 'take-away',
  [OrderFormat.TableOrder]: 'table-order',
  [OrderFormat.Delivery]: 'delivery',
  [OrderFormat.DriveThru]: 'drive-thru',
  [OrderFormat.BoxPickup]: 'box-pickup',
}

export const NotAvailable = 'N/A'

export const StockholmCoordinates = {
  latitude: 59.32932349999999,
  longitude: 18.0685808,
}

// TODO: Remove this once BoxPickup is supported for AOA
export const AOWAllOrderFormats = OrderFormat.All | OrderFormat.BoxPickup

export const AppTestIds = {
  Home: {
    OutletSearchInput: 'home-page-outlet-inline-search-input',
  },
}
