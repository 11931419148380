import {
  CheckDetails,
  CheckDetailsOutletExtent,
  CheckItem,
  CheckStatus,
  PaymentMethodType,
  PaymentType,
  CheckAdyenCreateOnlinePaymentResponse,
  CheckAdyenSubmitOnlinePaymentDetailsResponse,
  CheckMetadataType,
  OutletDetails,
} from '@ancon/wildcat-types'

import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import api from '../../../api'
import { AdyenPaymentMethodsResponse } from '../../adyen/types'
import { appLanguageSelector } from '../../app/store/appSelectors'
import getLocaleTag from '../../app/utils/getLocalTag'
import archiveLogger from '../../../utils/archiveLogger'
import serializeError from '../../../store/utils/serializeError'
import { CheckClientHeader } from '../constants'

import {
  checkCertainAmountSelector,
  checkCurrentCheckIdSelector,
  checkCurrentCheckOutletIdSelector,
  checkCurrentCheckTipAmountSelector,
} from './checkSelectors'

export const fetchCheckDetails = createAppAsyncThunk<
  { check: CheckDetails; outlet: CheckDetailsOutletExtent },
  {
    correlationId?: string
    virtualPOSClientId?: string
    outletId: string
    checkId: string
  }
>(
  'check/fetchCheckDetails',
  async ({ correlationId, virtualPOSClientId, outletId, checkId }) => {
    const response = await api.core.checks.get.details(
      {
        outletId,
        checkId,
      },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const splitCheckItems = createAppAsyncThunk<
  {
    created: CheckItem[]
    updated: CheckItem[]
  },
  {
    correlationId?: string
    virtualPOSClientId: string
    outletId: string
    checkId: string
    parts: number
    checkItemIds: string[]
  }
>(
  'check/splitCheckItems',
  async ({
    correlationId,
    virtualPOSClientId,
    outletId,
    checkId,
    parts,
    checkItemIds,
  }) => {
    const response = await api.core.items.put.split(
      {
        parts,
        checkItemIds,
      },
      { outletId, checkId },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const updateCheckMetadata = createAppAsyncThunk<
  { check: CheckDetails },
  {
    correlationId?: string
    virtualPOSClientId: string
    outletId: string
    checkId: string
    type: CheckMetadataType
    reference: string
    metadata: string
  }
>(
  'check/updateCheckMetadata',
  async ({
    correlationId,
    virtualPOSClientId,
    outletId,
    checkId,
    type,
    reference,
    metadata,
  }) => {
    const response = await api.core.checks.put.metadata(
      {
        type,
        reference,
        metadata,
      },
      { outletId, checkId },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const extractCertainCheckItems = createAppAsyncThunk<
  {
    originalCheck: CheckDetails
    mergeBackAt: string
    extractedCheck: CheckDetails
  },
  {
    correlationId: string
    virtualPOSClientId: string
    outletId: string
    checkId: string
    extractCheckItems: Record<string, number>
  }
>(
  'check/extractCertainCheckItems',
  async ({
    correlationId,
    virtualPOSClientId,
    outletId,
    checkId,
    extractCheckItems,
  }) => {
    const response = await api.core.checks.put.extractCertainItems(
      {
        extractCheckItems,
      },
      { outletId, checkId },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const extractPartialCheck = createAppAsyncThunk<
  {
    originalCheck: CheckDetails
    mergeBackAt: string
    extractedCheck: CheckDetails
  },
  {
    correlationId: string
    virtualPOSClientId: string
    outletId: string
    checkId: string
    parts: number
    extractParts: number
  }
>(
  'check/extractPartialCheck',
  async ({
    correlationId,
    virtualPOSClientId,
    outletId,
    checkId,
    parts,
    extractParts,
  }) => {
    const response = await api.core.checks.put.extractPartial(
      {
        parts,
        extractParts,
      },
      {
        outletId,
        checkId,
      },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const mergeCheckWithParent = createAppAsyncThunk<
  {
    parentCheck: CheckDetails
  },
  {
    correlationId: string
    virtualPOSClientId: string
    outletId: string
    checkId: string
  }
>(
  'check/mergeCheckWithParent',
  async ({ correlationId, virtualPOSClientId, outletId, checkId }) => {
    const response = await api.core.checks.put.mergeWithParent(
      {},
      { outletId, checkId },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const fetchCheckStatus = createAppAsyncThunk<
  { status: CheckStatus },
  {
    correlationId?: string
    outletId: string
    checkId: string
  }
>(
  'check/fetchCheckStatus',
  async ({ correlationId, outletId, checkId }) => {
    const response = await api.core.checks.get.status(
      {
        outletId,
        checkId,
      },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
      },
    )

    return {
      status: response.data.status,
    }
  },
  { serializeError },
)

export const fetchCheckOutletDetails = createAppAsyncThunk<
  OutletDetails | null,
  { outletId: string }
>(
  'check/fetchCheckOutletDetails',
  async ({ outletId }) => {
    const response = await api.core.outlet.get.details({
      outletId,
    })

    return response.data ?? null
  },
  { serializeError },
)

// MARK: Adyen

export const fetchCheckAdyenPaymentMethods = createAppAsyncThunk<
  {
    paymentProviderData: AdyenPaymentMethodsResponse
    useBetaOnline: boolean
  },
  {
    correlationId?: string
    virtualPOSClientId: string
  }
>(
  'check/fetchCheckAdyenPaymentMethods',
  async ({ correlationId, virtualPOSClientId }, { getState }) => {
    const language = appLanguageSelector(getState())

    const outletId = checkCurrentCheckOutletIdSelector(getState())!
    const checkId = checkCurrentCheckIdSelector(getState())!

    const response = await api.core.checks.get.onlinePaymentMethods(
      {
        outletId,
        checkId,
        methodType: PaymentMethodType.Adyen,
        paymentProviderData: JSON.stringify({
          channel: 'pos',
          shopperLocale: getLocaleTag(language).replace('-', '_'),
        }),
      },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const checkCreateAdyenPayment = createAppAsyncThunk<
  CheckAdyenCreateOnlinePaymentResponse,
  {
    data: any
    correlationId: string
    virtualPOSClientId: string
  }
>(
  'check/checkCreateAdyenPayment',
  async ({ correlationId, virtualPOSClientId, data }, { getState }) => {
    const outletId = checkCurrentCheckOutletIdSelector(getState())!
    const checkId = checkCurrentCheckIdSelector(getState())!

    const tipAmount = checkCurrentCheckTipAmountSelector(getState())
    const certainAmount = checkCertainAmountSelector(getState())

    // Sent to archive service
    const debugInfo = {
      CheckId: checkId,
      OutletId: outletId,
      CorrelationId: correlationId,
      Method: 'Adyen Checkout',
    }

    archiveLogger.debug('Payment started', debugInfo)

    const response = await api.core.checks.post.createOnlinePayment(
      {
        paymentProviderData: JSON.stringify(data),
        methodType: PaymentMethodType.Adyen,
        paymentType:
          certainAmount != null ? PaymentType.Part : PaymentType.Full,
        ...(certainAmount != null && { certainAmount }),
        ...(tipAmount != null && { tipAmount }),
      },
      {
        outletId,
        checkId,
      },
      {
        'x-channel': 'pos',
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    return response.data
  },
  { serializeError },
)

export const checkAdyenAdditionalDetails = createAppAsyncThunk<
  CheckAdyenSubmitOnlinePaymentDetailsResponse | null,
  {
    correlationId: string
    virtualPOSClientId: string
    data: any
    outletId: string
    checkId: string
  }
>(
  'check/checkAdyenAdditionalDetails',
  async ({ correlationId, virtualPOSClientId, data, outletId, checkId }) => {
    const response = await api.core.checks.post.onlinePaymentDetails(
      {
        paymentProviderData: JSON.stringify(data),
      },
      {
        outletId,
        checkId,
      },
      {
        'x-channel': 'pos',
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )

    // Bug: Core can respond with 423 and no data
    return response?.data ?? null
  },
  { serializeError },
)

// MARK: Receipts

export const sendCheckReceiptEmail = createAppAsyncThunk<
  void,
  {
    correlationId: string
    virtualPOSClientId: string
    outletId: string
    checkId: string
    paymentId: string
    email: string
  }
>(
  'check/sendReceiptEmail',
  async ({
    correlationId,
    virtualPOSClientId,
    outletId,
    checkId,
    paymentId,
    email,
  }) => {
    await api.core.checks.put.sendReceipt(
      {
        toEmail: email,
      },
      {
        outletId,
        checkId,
        paymentId,
      },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )
  },
  { serializeError },
)

export const downloadCheckReceipt = createAppAsyncThunk<
  Blob,
  {
    correlationId: string
    virtualPOSClientId: string
    outletId: string
    checkId: string
    paymentId: string
  }
>(
  'check/downloadReceipt',
  async ({
    correlationId,
    virtualPOSClientId,
    outletId,
    checkId,
    paymentId,
  }) => {
    const response = await api.core.checks.put.downloadReceipt(
      {},
      {
        outletId,
        checkId,
        paymentId,
      },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
      {
        responseType: 'blob',
      },
    )

    return response.data
  },
  { serializeError },
)

export const applyCheckDiscount = createAppAsyncThunk<
  void,
  {
    correlationId: string
    virtualPOSClientId: string
    outletId: string
    checkId: string
    discountCode: string
  }
>(
  'check/applyDiscount',
  async ({
    correlationId,
    virtualPOSClientId,
    outletId,
    checkId,
    discountCode,
  }) => {
    await api.core.checks.put.discount(
      {
        code: discountCode,
      },
      {
        outletId,
        checkId,
      },
      {
        'x-correlation-id': correlationId,
        client: CheckClientHeader,
        clientid: virtualPOSClientId,
      },
    )
  },
  { serializeError },
)
